import React, { Component } from 'react';
import { Input, Form, Radio } from 'antd';
import { Dialog, ViewTable, Status, Layout, Button } from '@/components';
import { FormInstance } from 'antd/lib/form';
import WebApi from '@/web/api';
import { LibAuthCode } from 'lib/Auth';

export default class RepositoryPage extends Component {

  private form: FormInstance | null = null
  private updateForm: FormInstance | null = null
  private table: ViewTable | null = null

  queryParams = {
    keyword: '',
  };

  state = {
    visible: false,
    visible2: false,
    initialValues: {}
  };

  render() {
    const { visible, visible2, initialValues } = this.state;

    return (
      <Layout.Page>
        <Layout.Toolbar title="仓库列表">
          <Button type="primary" authCode={LibAuthCode.repository.create} onClick={() => this.setState({ visible: true })}>新增仓库</Button>
          <Button.Refresh onClick={() => {
            this.table?.refresh();
          }}/>
        </Layout.Toolbar>

        <ViewTable
          flex1
          ref={r => this.table = r}
          rowKey={row => row.repositoryId}
          fetch={() => {
            return WebApi.repository_list({withProduct: true})
          }}
          pagination={false}
          columns={[
            {
              title: '仓库编号',
              dataIndex: 'repositoryId',
              align: 'center',
              width: 160,
              render: (v, r) => {
                return <>
                  {v}
                  {
                    r.isDefault === '1' &&
                    <div style={{position: 'absolute', top: 0, left: 0, lineHeight: 1, color: 'rgb(235, 84, 76)'}}>
                      <i className="iconfont icon-moren" style={{fontSize: 30}}></i>
                    </div>
                  }
                </>
              }
            },
            {
              title: '仓库',
              dataIndex: 'repositoryName',
              width: 200,
            },
            {
              title: '商品种类',
              dataIndex: 'productCount',
              align: 'right',
              width: 150,
            },
            {
              title: '商品数量',
              dataIndex: 'skuCount',
              align: 'right',
              width: 150,
            },
            {
              title: '商品金额',
              dataIndex: 'amount',
              align: 'right',
              width: 150,
            },
            {
              title: '状态',
              width: 100,
              align: 'center',
              dataIndex: 'status',
              render: v => <Status status={v}/>
            },
            {
              title: '仓库描述',
              dataIndex: 'repositoryDesc',
              width: 200,
            },
            {
              title: '仓库地址',
              dataIndex: 'repositoryAddress',
              width: 300,
            },
            // {
            //   title: '创建时间',
            //   dataIndex: 'createdAt',
            //   width: 200,
            // },
          ]}
          actionButtons={[
            {
              type: 'update',
              authCode: LibAuthCode.repository.update,
              onClick: row => {
                this.setState({
                  initialValues: row,
                  visible2: true,
                });
              }
            },
            {
              type: 'delete',
              authCode: LibAuthCode.repository.delete,
              disabled: (row, index, dataSource) => row.skuCount !== 0 || dataSource.length === 1,
              onClick: row => {
                this.clickDelete(row);
              }
            }
          ]}
        />

        {/* 新建仓库 */}
        <Dialog full={false} visible={visible} title="新建仓库"
          onOk={this.clickCreate}
          onCancel={() => this.setState({visible: false})}>
          <div>
            <Form ref={r => this.form = r} labelCol={{span: 5}}>
              <Form.Item label="仓库名称" name="repositoryName" rules={[{
                required: true,
                message: '请填写仓库名称'
              }]}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="仓库描述" name="repositoryDesc">
                <Input placeholder=""/>
              </Form.Item>
              <Form.Item label="仓库地址" name="repositoryAddress" rules={[{
                required: true,
                message: '请填写仓库地址'
              }]}>
                <Input placeholder=""/>
              </Form.Item>
            </Form>
          </div>
        </Dialog>

        {/* 修改仓库 */}
        <Dialog visible={visible2} title="修改仓库"
          full={false}
            onOk={this.clickUpdate}
            destroyOnClose={true}
            onCancel={() => this.setState({visible2: false})}>
            <div style={{width: 445}}>
              <Form ref={r => this.updateForm = r} labelCol={{span: 5}} initialValues={initialValues}>
                <Form.Item hidden name="repositoryId">
                  <Input placeholder="" hidden/>
                </Form.Item>
                <Form.Item label="仓库名称" name="repositoryName" rules={[{
                  required: true,
                  message: '请填写仓库名称'
                }]}>
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item label="仓库描述" name="repositoryDesc" rules={[{
                  required: true,
                  message: '请填写仓库描述'
                }]}>
                  <Input placeholder=""/>
                </Form.Item>
                <Form.Item label="仓库地址" name="repositoryAddress" rules={[{
                  required: true,
                  message: '请填写仓库地址'
                }]}>
                  <Input placeholder=""/>
                </Form.Item>
                <Form.Item label="设为默认" name="isDefault" rules={[{
                  required: true,
                }]}>
                  <Radio.Group>
                    <Radio value="1">是</Radio>
                    <Radio value="0">否</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="仓库状态" name="status" rules={[{
                  required: true,
                }]}>
                  <Radio.Group>
                    <Radio value="1">启用</Radio>
                    <Radio value="0">禁用</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>
          </Dialog>
      </Layout.Page>
    )
  }

  // 新建仓库
  clickCreate = async () => {
    try {
      const values: any = await this.form?.validateFields();
      this.setState({
        visible: false
      });
      
      WebApi.repository_create(values)
      .then(() => {
        showSuccess('保存成功');
        this.table?.reset(this.queryParams);
      })
    } catch (e) {

    }
  }

  // 修改
  clickUpdate = async () => {
    try {
      const values: any = await this.updateForm?.validateFields();
      this.setState({
        visible2: false
      });
      
      WebApi.repository_update(values)
      .then(() => {
        showSuccess('保存成功');
        this.table?.reset(this.queryParams);
      })
    } catch (e) {
      console.error(e);
    }
  }

  // 删除仓库
  clickDelete = ({ repositoryName, repositoryId }) => {
    Dialog.confirm({
      content:  `确认删除 “${repositoryName}” 吗？`,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        WebApi.repository_delete({
          repositoryId,
        })
        .then(() => {
          showSuccess('删除成功');
          this.table?.reset();
        })
      }
    });
  }
}
